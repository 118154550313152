<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12 md12>
					<pui-field-set>
						<v-layout wrap>
							<!-- IDTERMINAL -->
							<v-flex xs12 md1>
								<pui-text-field
									:id="`idterminal-mterminal`"
									:label="$t('mterminal.idterminal')"
									v-model="model.idterminal"
									disabled
									toplabel
									maxlength="10"
								>
								</pui-text-field>
							</v-flex>
							<!-- CODTERMINAL -->
							<v-flex xs12 md1>
								<pui-text-field
									:id="`codterminal-mterminal`"
									:label="$t(`mterminal.codterminal`)"
									itemValue="codterminal"
									v-model="model.codterminal"
									required
									toplabel
									maxlength="10"
								>
								</pui-text-field>
							</v-flex>
							<!-- IDPUERTO -->
							<v-flex xs12 md2>
								<pui-select
									id="idpuertolocal"
									attach="idpuertolocal"
									:label="$t('mterminal.idpuertolocal')"
									toplabel
									clearable
									required
									:disabled="formDisabled"
									v-model="model"
									modelName="vlupmpuertolocal"
									:itemsToSelect="[{ id: model.idpuertolocal }]"
									:modelFormMapping="{ id: 'idpuertolocal' }"
									itemValue="id"
									itemText="descripcion"
									:order="{ descripcion: 'asc' }"
								></pui-select>
							</v-flex>
						</v-layout>
						<v-layout>
							<!-- NAME -->
							<v-flex xs12 md2>
								<pui-text-field
									:id="`name-mterminal`"
									v-model="model.name"
									:label="$t('mterminal.name')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="30"
								></pui-text-field>
							</v-flex>
							<!-- EMAIL -->
							<v-flex xs12 md5>
								<pui-text-field
									:id="`email-mterminal`"
									v-model="model.email"
									:label="$t('mterminal.email')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="250"
								></pui-text-field>
							</v-flex>
							<!-- TELFCONTACTO -->
							<v-flex xs12 md2>
								<pui-text-field
									:id="`telfcontacto-mterminal`"
									v-model="model.telfcontacto"
									:label="$t('mterminal.telfcontacto')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="25"
								></pui-text-field>
							</v-flex>
						</v-layout>
							<!-- FECBAJA -->
						<v-layout>
							<v-flex xs12 md3>
								<pui-date-field
								:id="`fecbaja-mterminal`"
								:label="$t(`mterminal.fecbaja`)"
								v-model="model.fecbaja"
								toplabel
								time
								></pui-date-field>
							</v-flex>
						</v-layout>
					</pui-field-set>
				</v-flex>
			</v-layout>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'mterminal-form',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'mterminal',
			refreshSelect: 0
		};
	},
	computed: {
		
		codpueItemsToSelect() {
			return [{ idpuertolocal: this.model.idpuertolocal }];
		},
	}
};
</script>
